@import "../../Styles/variables.scss";

.login-language-selection-container {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $primary-color;

  .ant-select {
    width: 183px;
    text-align: center;
    color: $primary-color;
    .ant-select-arrow {
      color: $primary-color;
    }
  }

  .ant-select-selector {
    color: $primary-color;
    text-transform: uppercase;
    border: 1px solid $primary-color !important;
    border-radius: 4px !important;
  }

  .ant-select-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $primary-color;
  }

  .ant-select-selection-item {
    color: $primary-color !important;
    font-weight: 500;
  }
}

.login-language-selection {
  .ant-select-item {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: $primary-color;
    text-align: center;
  }
  .ant-select-item-option {
    .ant-select-item-option-content {
      color: $text-color-withop;
    }
  }
  .ant-select-item-option-selected {
    color: $primary-color !important;
    line-height: 15.73px;
    .ant-select-item-option-content {
      color: $primary-color;
    }
  }
}
