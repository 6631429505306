@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

.info-layout-container {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $background-color;

  .header-container {
    background-color: rgba(255, 255, 255, 0.8);
    height: 90px;
    padding-top: 15px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0rem 0 0vw;
    cursor: pointer;

    .title {
      font-family: 'MuseoSans';
      font-size: 1.2rem;
      color: $logo-text-color;
      font-weight: 700;
      margin-right: 0.5rem;
    }

    .title-sub {
      font-weight: 100 !important;
      font-family: 'MuseoSans';
      font-size: 1.2rem;
      color: $logo-text-color;
    }

    .logo {
      height: 53px;
      padding-left: 2vw;

      img {
        object-fit: cover;
        height: auto;
        height: 85%;
        margin-top: 6px;
      }
    }

    .country-name {
      font-size: 0.65rem;
      color: $logo-text-color;
      margin-top: -5px;
      font-family: 'MuseoSans';
    }
  }

}
