.select-report-bar {
    margin-bottom: 25px;

    .report-selector {
        width: 100%;

        .ant-select-selector {
            padding: 5px;
            border-width: 1px;
            border-radius: 10px !important;
        }

        .placeholder-label {
            font-size: small;
        }
    }

    .report-chip {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
        width: 287px;
        border-width: 0;
        border-radius: 6px;
        background-color: #e3f3fb;
        color: #7d858a;
        padding: 10px;
        margin-left: 3px;
        margin-right: 5px;
        margin-bottom: 3px;
        margin-top: 3px;

        .report-label {
            margin-right: 10px;
            font-size: 13px;
        }
    }
}

.no-reports-selected {
    background-color: #ffffff;
    border-radius: 10px;
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}