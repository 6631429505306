@import '../../Assets/Fonts/fonts.scss';
@import '../../Styles/variables.scss';

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}


.login-container {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $outside-background-color;

  .login-img {
    height: 100%;
  }

  .login-img-waiting {
    height: 100%;
    animation: pulse 2s ease-in-out infinite;
  }

  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(196, 196, 196, 0) 0%, rgba(4, 104, 177, 0.2) 0.01%);
    pointer-events: none;
  }

  .login-img-container {
    height: 100vh;
    background-position-x: 90%;
    background-position-y: 100%;
    flex-direction: column;
    justify-content: flex-end;
    display: flex;
    flex: 1;
    text-align: left;
    background-size: 150vh;

    @media (max-width: $lg-size) {
      text-align: center;
      justify-content: center;
    }

    .text-ctn {
      position: absolute;
      margin-left: 2vw;
      margin-bottom: 8vh;
      font-family: $secondary-font-family;
      font-size: 4rem;
      color: $outside-background-color;
      line-height: 1.2em;
      text-transform: uppercase;

      @media (max-width: $lg-size) {
        font-size: 2.2rem;
        margin-top: 1rem;
        margin-bottom: unset;
        line-height: 1.5em;
      }
    }
  }

  .login-text-contents {
    display: flex;
    flex-direction: column;
    margin-top: 5vh;

    .login-text-sign {
      font-size: 2.25rem;
      line-height: 2.5rem;
      color: $title-text-color;
      font-weight: 600;
      margin-bottom: 30px;

      .login-text-welcome {
        font-size: 0.8rem;
        font-weight: 400;
      }
    }
  }

  .ant-btn-primary {
    background: linear-gradient(to right, $gradient-background-start, $gradient-background-end);
    border: none;
    height: 2.4rem;
    font-size: 1rem;
    border-radius: 0.3vw;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
  }

  .form-input-box {
    font-size: 14px;
    height: 40px;
    border-radius: 0.3rem;
  }
  
  .form-item-header {
    color: #3a3541;
    opacity: 0.8;
  }

  .forget-password {
    display: flex;
    justify-content: flex-end;
    color: #3a3541;
    opacity: 0.8;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .language-section {
    display: flex;
    justify-content: flex-end;
    color: #3a3541;
    opacity: 0.8;
  }

  .row-container {
    height: auto;
    display: flex;
    flex-direction: column;

    .language-row {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin-bottom: 30px;
      margin-right: 62px;
    }

    .centred-row {
      margin-left: 62px;
      margin-right: 62px;
    }
  }

  .logo-row {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 62px;
    margin-right: 62px;
  }

  .error-message-icon {
    color: rgba(255, 77, 79, 0.8);
    margin-right: 5px;
    margin-bottom: 0;
    font-size: 14px;
  }

  .error-message-text {
    color: rgba(255, 77, 79, 0.8);
    font-size: 14px;
    margin-left: 2px;
  }

  .expiry-message-text {
    color: rgba(255, 77, 79, 0.8);
    font-size: 13px;
    margin-left: 2px;
  }

  .back-to-sign {
    color: #9155fd;
    cursor: pointer;
  }
}
