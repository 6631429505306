@import '../../Styles/variables.scss';

.layout-sider-container {
  background-color: $background-color;
  height: 100vh;

  .country-flag {
    margin-top: 27px;
    margin-left: -8px;
  }

  .country-name {
    font-size: 0.65rem;
    color: $logo-text-color;
    margin-top: -2px;
    font-family: 'MuseoSans';
  }

  .toggle-nav-btn {
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    text-align: center;
    width: calc(100% - 20px);
    border: 1px solid $primary-color;
    color: $primary-color;
    border-radius: 5px;
    height: 32px;
    vertical-align: middle;
    padding: 0px;
    font-size: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .layout-sider-div-container {
    padding-top: 10px;
    overflow-y: auto;

    .layout-sider-heading-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 15px;
      padding-right: 10px;
      padding-left: 20px;
      cursor: pointer;

      .sider-logo-system-name {
        // display: flex;
        // flex-direction: column;

        .title {
          font-family: 'MuseoSans';
          font-size: 13px;
          color: $logo-text-color;
          font-weight: 700;
          margin-right: 5px;
        }

        .title-sub {
          font-weight: 100 !important;
          font-family: 'MuseoSans';
          font-size: 12px;
          color: $logo-text-color;
          margin-top: -5px;
        }
      }

      .logo {
        height: 60px;
        width: 51px;

        img {
          object-fit: cover;
          height: auto;
          height: 78%;
          margin-top: 6px;
          margin-right: 7px;
        }
      }
    }

    .sider-menu-container {
      overflow-y: auto;
      max-height: 80vh;
      padding-top: 10px;
      padding-right: 8px;

      .ant-menu-item-selected {
        background: linear-gradient(to right, $gradient-background-start, $gradient-background-end);
        color: $dark-text-color;
        border-top-right-radius: 22px;
        border-bottom-right-radius: 22px;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f0e9fd;
      }

      &::-webkit-scrollbar-thumb {
        background: #d5d0dd;
      }

      &::-webkit-scrollbar-thumb:hover {
        cursor: pointer;
      }
    }
  }
}
