@import "../../../Styles/colors.userProfile.scss";
@import "../../../Styles/variables.scss";

.user-profile {
  padding: 0px 50px;

  .login-language-selection-txt .ant-select {
    width: 120px;
  }

  .card-container {
    margin-bottom: 20px;
    width: 100%;
    border-radius: 10px;
    
    .info-view {
      .title-icon {
        padding-right: 10px;
      }
    
      .title-text {
        color: $title-text-color;
        font-weight: 600;
      }
    
      .title {
        margin-bottom: 5px;
      }
    
      .field-key {
        color: $body-text-color;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    
      .field-value {
        color: $body-text-color;
        text-overflow: ellipsis;
        overflow: hidden;
    
        .row {
          display: flex;
          width: 40%;
          align-items: center;
          justify-content: space-between;
    
          .key {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: flex-start;
          }
    
          .data {
            display: flex;
            width: 50%;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    
      .field {
        margin: 15px 25px;
        font-size: 0.8rem;
      }
    }
    
  }
  
  .mg-left-1 {
    margin-left: 1rem;
  }

  .mg-top-1 {
    margin-top: 1rem;
  }

  .mg-bottom-1 {
    margin-bottom: 1rem !important;
  }

  .profile-img {
    width: 170px;
  }

  .company-name {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $company-name-cl;
  }

  .btn-danger {
    color: $danger-btn !important;
    border-color: $danger-btn-border !important;
  }
}
