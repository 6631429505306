@import '../../Styles/variables.scss';

.header-container {
  background-color: $background-color;

  .toggle-btn {
    margin-left: -58px;
    color: $primary-color;
    cursor: pointer;
  }

  .ant-checkbox-inner {
    border-radius: 100%;
    border-color: $primary-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    background-color: $primary-color;
  }

}

.layout-header-container {
  height: 90px;
  padding-top: 15px;
}

.header-country-logo {
  height: 44px;
  width: 44px;
  background-color: $outside-background-color;
  border-radius: 50%;
  margin-top: 14px;
  text-align: center;
  img {
    margin: -14px 5px 7px 5px;
    object-fit: contain;
    height: 30px;
    width: 30px;
    cursor: pointer;

  }
  .icon {
    object-fit: contain;
    height: 44px;
    width: 44px;
    cursor: pointer;
  }
}

.header-prof {
  float: right;
}