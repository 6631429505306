.confirmation-popup {
  
    .icon {
      margin-top: 15px;
    }

    .primary-message {
        color: #3A3541;
        opacity: 0.8;
        margin-top: 30px;
        font-size: 15px;
    }

    .secondary-message {
      color: #3A3541;
      opacity: 0.8;
      margin-top: 20px;
      margin-top: 8px;
      text-align: center;
    }
    
}