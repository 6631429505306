@import "../../../Styles/variables.scss";

.filter-menu-item {
  .filter-title {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
}

.table-row-gray {
  background-color: #f0f0f0;
}

.user-data-download{
  margin-left: 15px;
  margin-top: 0.8rem;
}

.activate-user-icon {
  color: $primary-color !important;
  font-size: 15px;
}

.deactivate-user-icon {
  color: $error-color !important;
  font-size: 15px;
}

