$admin-bg-color: rgba(255, 166, 166, 0.42);
$admin-color: #d12800;

$root-bg-color: rgba(255, 0, 229, 0.15);
$root-color: #db00ff;

$department-bg-color: rgba(96, 27, 209, 0.13);
$department-color: #75009e;

$subrole-bg-color: rgba(96, 27, 209, 0.13);
$subrole-color: #75009e;

$consultant-bg-color: rgba(128, 255, 0, 0.12);
$consultant-color: #519e4f;

$seo-bg-color: rgba(176, 190, 197, 0.23);
$seo-color: #707070;

$gov-bg-color: rgba(185, 226, 244, 0.56);
$gov-color: #16b1ff;

$obs-bg-color: rgba(254, 241, 173, 0.55);
$obs-color: #ffa070;

$tec-rev-bg-color: rgba(198, 144, 251, 0.12);
$tec-rev-color: #9155fd;

$dev-partner-bg-color: rgba(255, 0, 229, 0.15);
$dev-partner-color: #db00ff;

$cert-bg-color: rgba(128, 255, 0, 0.12);
$cert-color: #519e4f;

$ministry-bg-color: rgba(198, 144, 251, 0.12);
$ministry-color: #9155fd;

$view-bg-color: rgba(176, 190, 197, 0.23);
$view-color: #707070;
