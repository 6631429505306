.chart-info-title {
    color: #3A3541;
    opacity: 0.8;
    margin-top: 30px;
    font-size: 15px;
}

.chart-info-body {
    color: #3A3541;
    opacity: 0.8;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 15px;
}